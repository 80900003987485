<!-- Alert.vue -->
<template>
  <div>
    <v-alert
      class="text-center"
      dismissible
      :value="!!message"
      :type="type"
      outlined
      border="left"
      @input="closeAlert"
      ><span v-html="message"></span>
    </v-alert>
  </div>
</template>


<script>
import store from '@/store'
import { onUnmounted, computed } from '@vue/composition-api'
import alertStoreModule from './alertStoreModule'

export default {
  setup() {
    

    const formatErrorMessage = (error) => {
      // console.error(error.response.status, error.response.data);
      let errorMessage = '';
      if (typeof error === 'string'){
        errorMessage = error
      } else {
        Object.entries(error).forEach(([key, value]) => {
        // if (Array.isArray(value)){
        // } else {
          if (typeof value === 'string') {
            errorMessage += `${key}: ${value} <br/>`
          } else {
            for (const idx in value){
              if (typeof value[idx] === 'object'){
                errorMessage += `${key}: ${formatErrorMessage(value[idx])}`
                
              } else {
                
                errorMessage += `${key}: ${value} <br/>`
              }
            }
          }
        });
      
      }
      return errorMessage
      
    }

    // ------------------------------------------------
    // Alert Message
    // ------------------------------------------------
    const message = computed(() => formatErrorMessage(store.state['comp-alert'].message))

    // ------------------------------------------------
    // Alert Type
    // ------------------------------------------------
    const type = computed(() => store.state['comp-alert'].types[store.state['comp-alert'].type] ? store.state['comp-alert'].types[store.state['comp-alert'].type] : 'warning')

    const closeAlert = ( () =>{
        store.commit('comp-alert/SET_MESSAGE', {})
    })


    return {
      message,
        type,
        formatErrorMessage,
        closeAlert
    }
  },
}
</script>

<style>
</style>
